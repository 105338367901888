.jazz_discount_outer {
  background-image: url("../Assets/imgs/gamenow_landing_page.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 20px 80px;
  min-height: 100vh;
}

.jazz_discount_outer h2 {
  padding: 25px;
  border: solid 1px #fff;
  margin-bottom: 20px;
  font-size: 28px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

.jazz_discount_outer p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 10px;
}
.jazz_discount_outer .p-bold {
  font-weight: 600;
}
.jazz_discount_outer a {
  color: #fff;
}
.jazz_discount_outer li {
  font-size: 14px;
  color: #fff;
  margin-bottom: 10px !important;
}

@media (max-width: 776px) {
  .jazz_discount_outer {
    padding: 20px 15px;
  }
  .jazz_discount_outer h2 {
    padding: 15px;
    font-size: 17px;
  }
  .jazz_discount_outer ol {
    padding-left: 20px;
  }
}
