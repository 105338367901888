@import url("../fonts/satoshi.css");

* {
  outline: none;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Satoshi-Regular" !important;
  overflow-x: hidden;
}

a,
span {
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

p,
ul {
  margin: 0;
  padding: 0;
}

.landing-bg {
  background-image: url("../Assets/imgs/gamenow_landing_page.webp");
  min-height: 100vh;
  padding: 10px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.landing-bg img {
  max-width: 100%;
  height: auto;
}

.landing-bg a {
  color: #fff;
  font-size: 14px;
}

.landing-bg a:hover {
  text-decoration: underline;
}

.subscrption-logo {
  text-align: center;
  padding: 10px 0;
}

.subscrption-content {
  max-width: 375px;
  margin: auto;
  padding: 0 10px;
}

.subscrption-inner .image img {
  width: 100%;
}

.subscrption-inner image img {
  width: 100%;
}

.code-number {
  display: flex;
  border: none;
  align-items: center;
  gap: 10px;
}

.code .fixed-cnt {
  border: 2px solid rgba(255, 200, 37, 0.5);
  border-radius: 15px;
  padding: 13px 10px;
  background: none;
  font-weight: 600;
  color: #fff;
}

.subscrption-content .form {
  background-color: #483e2d;
  border-radius: 15px;
  padding: 15px 20px;
  margin-top: -22px;
  z-index: 1;
  position: relative;
  text-align: center;
  border: 1px solid #6e6c67;
}

.subscrption-content .error {
  color: red;
  padding-top: 10px;
}

.input-content .text {
  color: #fff;
  margin-bottom: 15px;
  text-align: center;
}

.input {
  width: 85%;
  font-family: "Satoshi-Regular" !important;
}

.input input {
  border: 2px solid rgba(255, 200, 37, 0.5);
  padding: 15px 10px;
  border-radius: 15px;
  width: 87%;
  font-size: 16px;
  color: #fff;
  background: none;
  font-weight: 600;
}

.subscrption-content button {
  background: #fdca34;
  border: none;
  padding: 14px 8px;
  border-radius: 13px;
  color: #000;
  text-align: center;
  font-weight: 600;
  width: 96%;
  font-size: 17px;
  margin-top: 20px;
  cursor: pointer;
}

.subscrption-content button:hover {
  opacity: 0.8;
  transition: 0.2s ease-in-out;
}

.prepaid-text {
  margin-top: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}

.condition span {
  background: black;
  height: 5px;
  width: 5px;
  display: inline-block;
  border-radius: 50%;
  margin: 0 8px;
}

.condition {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 5px;
  align-items: center;
}

/* OTP Screen */
.otp-inputs {
  display: flex;
  justify-content: space-evenly;
}

.otp-inputs input {
  width: 45px;
  height: 45px;
  padding: 5px;
  border-radius: 15px;
  border: 1px solid rgba(255, 200, 37, 0.5);
  text-align: center;
  background: none;
  color: #fff;
}

.otp-inputs input:focus {
  border: 2px solid rgba(255, 200, 37, 0.5);
}

.resend-code {
  text-align: center;
  margin-top: 20px;
  font-size: 15px;
  color: #fff;
}

.resend-code .timer-text span {
  color: rgba(253, 202, 52, 1);
  font-weight: 700;
  cursor: pointer;
}

.resend-code .timer-text span:hover {
  text-decoration: underline;
}

.resend-code a {
  font-size: 16px;
}

.not-received .timer-text {
  font-weight: 700;
}

@media (max-width: 768px) {
  .landing-bg {
    background-image: url("../Assets/imgs/gamenow_mobile_bg.webp");
    min-height: 100vh;
    padding: 10px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
